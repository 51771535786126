import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { ReactSVG } from "react-svg"

// ---------------------------------------------------------

import { hasDarkBackground } from "@lib/utils"

import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
SwiperCore.use([Navigation, Pagination, A11y, Autoplay])

// ---------------------------------------------------------

import {
  navigation_button,
  button_next,
  button_previous,
  buttons_container,
  carousel_wrapper,
  hidden,
  is_card,
  is_content_carousel,
  is_quote,
  slider,
  swipe_child,
  swipe_container,
  swipe_item,
  theme_light,
  theme_dark
} from "./styles.module.scss"

const themeOptions = {
  light: theme_light,
  dark: theme_dark
}

// ---------------------------------------------------------

const Carousel = ({
  backgroundColor,
  backgroundImage,
  children,
  contentType,
  hasArrowNavs = true,
  hasAutoPlay = true,
  contentCarousel,
  modulesCollection,
  theme = "dark",
  uniqueId
}) => {
  const [mounted, setMounted] = useState(false)

  // ---------------------------------------------------------

  // get the content type from the CMS input if not provided
  if (!contentType) contentType = modulesCollection?.items[0].__typename.toLowerCase()
  const isColorSplitCard =
    modulesCollection?.items[0].theme === "Color split" ||
    children[0]?.props?.theme === "Color split"

  const isQuote = contentType === "quote"
  const isCard = contentType === "card"

  const swiperOptions = {
    card: {
      breakpoints: {
        ...(isColorSplitCard
          ? {
              1080: {
                slidesPerView: 3.5,
                spaceBetween: 20
              },
              850: {
                slidesPerView: 2.5
              },
              650: {
                slidesPerView: 2
              },
              450: {
                slidesPerView: 1.5,
                spaceBetween: 10
              }
            }
          : {
              960: {
                slidesPerView: 3,
                spaceBetween: 20
              },
              600: {
                slidesPerView: 2,
                spaceBetween: 10
              }
            }),
        0: { slidesPerView: 1.1, spaceBetween: 5 }
      }
    },
    slide: {
      breakpoints: {
        600: {
          allowTouchMove: false
        }
      }
    }
  }

  useEffect(() => {
    setMounted(true)
  }, [])

  // ---------------------------------------------------------

  // Show a regular div if there is only one child
  if (children && 1 > children.length) return <div>{children}</div>

  if (backgroundColor && hasDarkBackground(backgroundColor)) theme = "light"

  const classes = {
    [is_content_carousel]: contentCarousel,
    [is_quote]: isQuote,
    [is_card]: isCard
  }

  // ---------------------------------------------------------

  return (
    mounted && (
      <section
        aria-label={`${uniqueId}-carousel`}
        className={classNames(carousel_wrapper, {
          [`background_${backgroundColor}`]: backgroundColor,
          [themeOptions[theme]]: themeOptions[theme]
        })}
        style={
          backgroundImage
            ? {
                backgroundImage: `url(${backgroundImage.url})`
              }
            : null
        }
      >
        <div className={classNames(swipe_container, classes)}>
          <Swiper
            className={classNames(classes, slider)}
            allowTouchMove={true}
            autoplay={{ enabled: hasAutoPlay, delay: 6000 }}
            loop={isQuote || isCard}
            keyboard={{ enabled: true, onlyInViewport: true }}
            mousewheel={{ forceToAxis: true }}
            navigation={{ nextEl: `#next-slide_${uniqueId}`, prevEl: `#prev-slide_${uniqueId}` }}
            pagination={{ clickable: true }}
            shortSwipes={true}
            a11y={{
              enabled: true,
              containerMessage: `${contentType} Carousel`,
              firstSlideMessage: "This is the first slide",
              lastSlideMessage: "This is the last slide"
            }}
            {...swiperOptions[contentType]}
          >
            {children &&
              children.map((child, idx) => {
                return (
                  <SwiperSlide className={classNames(swipe_item, classes)} key={idx}>
                    <div className={swipe_child}>{child}</div>
                  </SwiperSlide>
                )
              })}
          </Swiper>

          <div className={classNames(classes, buttons_container)}>
            <button
              className={classNames(navigation_button, button_previous, {
                [hidden]: !hasArrowNavs
              })}
              id={`prev-slide_${uniqueId}`}
            >
              <span>
                <ReactSVG src={`/icons/chevron.svg`} />
              </span>
            </button>

            <button
              className={classNames(navigation_button, button_next, {
                [hidden]: !hasArrowNavs
              })}
              id={`next-slide_${uniqueId}`}
            >
              <span>
                <ReactSVG src={`/icons/chevron.svg`} />
              </span>
            </button>
          </div>
        </div>
      </section>
    )
  )
}

Carousel.propTypes = {
  /**
   * Specifies the background color
   */
  backgroundColor: PropTypes.oneOf(["off-black", "cement", "anvil", "ice", "lagoon", "limoncello"]),

  /**
   * Source for background image. When specified, overrides `backgroundColor` prop.
   */
  backgroundImage: PropTypes.shape({
    description: PropTypes.string,
    height: PropTypes.number,
    url: PropTypes.string,
    width: PropTypes.number
  }),

  /**
   * Children are the components that the carousel will display
   */
  children: PropTypes.node,

  /**
   * Represents the type of item (only one type per carousel) that was put into the carousel
   */
  contentType: PropTypes.oneOf(["slide", "quote", "card"]),

  /**
   * Selects whether the carousel will automatically advance to the next slide every 5 seconds.
   */
  hasAutoPlay: PropTypes.bool,

  /**
   * Selects whether the carousel has arrow buttons to move to the previous/next slide
   */
  hasArrowNavs: PropTypes.bool,

  /**
   * Specifies if the carousel is the content carousel
   */
  contentCarousel: PropTypes.bool,

  /**
   * Array of items passed into the Carousel by the CMS - should not be altered separately
   */
  modulesCollection: PropTypes.shape({
    items: PropTypes.array
  }),

  /**
   * Light/Dark theme for pagination
   */
  theme: PropTypes.oneOf(Object.keys(themeOptions)),

  /**
   * A unique ID to differentiate multiple carousels (slide, quote carousel, or card carousel) on one page
   */
  uniqueId: PropTypes.string.isRequired
}

export default Carousel
