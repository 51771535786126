import { useEffect, useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { parameterize, hasDarkBackground } from "@lib/utils"

// ---------------------------------------------------------

import Button from "@components/button"
import ErrorBoundary from "@components/error-boundary/component"
import SVG from "@components/image/svgs"

// ---------------------------------------------------------

import {
  button_container,
  dark,
  hide_on_mobile,
  label_dark,
  label_light,
  logo_container,
  logo_train_animated,
  logo_train_fade_in,
  logo_train_fade_out,
  add_vertical_padding,
  logo_train_image,
  logo_train_wrapper,
  logo_train,
  logos_light,
  logos_dark,
  linked_logo
} from "./styles.module.scss"

// ---------------------------------------------------------

const DISPLAY_COUNT = 8

// ---------------------------------------------------------

export const getAnimatedLogoArrayValues = (logos) => {
  if (logos.length > DISPLAY_COUNT) {
    const current = []
    const waiting = []

    for (let i = 0; i < logos.length; i++) {
      if (i < DISPLAY_COUNT) {
        current.push(logos[i])
      } else {
        waiting.push(logos[i])
      }
    }

    return {
      current,
      waiting
    }
  }
}

// ---------------------------------------------------------

const LogoTrain = ({
  className,
  animated = false,
  backgroundColor = "off-black",
  button,
  logos,
  title,
  label
}) => {
  // -------------------------------------------------------

  const formattedButton = button && (
    <div className={button_container} data-testid="logo-train-button">
      <Button
        theme={parameterize(button.theme) || "arrow-light"}
        labelAttr={button.labelAttr}
        url={button.url}
      >
        {button.labelAttr}
      </Button>
    </div>
  )

  // -------------------------------------------------------

  const animatedLogoArrayValues = getAnimatedLogoArrayValues(logos)
  const [logoArrays, setLogoArrays] = useState({ ...animatedLogoArrayValues })
  const [nextIndex, setNextIndex] = useState(0)

  useEffect(() => {
    const getLogoArrays = () => {
      let current = [...logoArrays.current]
      let waiting = [...logoArrays.waiting]

      // remove the nextIndex and replace with the next image within the waiting array
      const newLogo = waiting[0]
      const oldLogo = current[nextIndex]

      if (newLogo) {
        current[nextIndex] = newLogo
      }

      waiting.shift()
      waiting.push(oldLogo)

      // find the new next index
      let newIndex = Math.floor(Math.random() * DISPLAY_COUNT)
      if (newIndex === nextIndex) newIndex = newIndex === 0 ? current.length - 1 : 0

      setLogoArrays({
        current,
        waiting
      })

      setNextIndex(newIndex)
    }

    if (animated) {
      const id = setInterval(() => {
        getLogoArrays()
      }, 2000)
      return () => clearInterval(id)
    }
  }, [animated, logoArrays, nextIndex])

  // -------------------------------------------------------

  const getImages = (showAnimated) => {
    const containerClasses = classNames(className, logo_train_wrapper, {
      [logo_train_animated]: showAnimated,
      [hide_on_mobile]: logos.length > DISPLAY_COUNT
    })

    const logoArray = showAnimated ? logoArrays.current : logos
    const logoColor = backgroundDark ? logos_light : logos_dark

    // ---------------------------------------------------------

    const getSvg = (logo) => (
      <SVG
        className={classNames(logoColor, logo_train_image)}
        title={logo.alt}
        src={logo.src}
        width={120}
        height={40}
      />
    )

    return (
      <div className={containerClasses}>
        {logoArray.map((logo, index) => {
          if (logo) {
            let imageClasses = classNames(logo_container, {
              [logo_train_fade_in]: nextIndex !== index || showStatic,
              [logo_train_fade_out]:
                nextIndex === index && logos.length > DISPLAY_COUNT && showAnimated
            })

            return (
              <div className={imageClasses} key={index}>
                <ErrorBoundary>
                  {logo.link ? (
                    <a className={linked_logo} href={logo.link} data-testid="logo-train-link">
                      {getSvg(logo)}
                    </a>
                  ) : (
                    getSvg(logo)
                  )}
                </ErrorBoundary>
              </div>
            )
          }

          return null
        })}
      </div>
    )
  }

  // -------------------------------------------------------

  const classes = classNames(logo_train, {
    [`background_${backgroundColor}`]: backgroundColor,
    [add_vertical_padding]: title || label
  })

  const backgroundDark = backgroundColor && hasDarkBackground(backgroundColor)

  const showStatic = !animated || logos.length <= DISPLAY_COUNT
  const showAnimated = animated && logos.length > DISPLAY_COUNT

  // -------------------------------------------------------

  return (
    <div className={classes}>
      {title && (
        <h2 className={backgroundDark ? null : dark} data-testid="logo-train-title">
          {title}
        </h2>
      )}
      {label && <p className={backgroundDark ? label_light : label_dark}>{label}</p>}
      {showAnimated && <div>{getImages(true)}</div>}
      {showStatic && getImages(false)}
      {formattedButton}
    </div>
  )
}

LogoTrain.propTypes = {
  /**
   * Boolean that determines if the logo train is animated.
   */
  animated: PropTypes.bool,

  /**
   * Specifies the logo train background color.
   */
  backgroundColor: PropTypes.oneOf([
    "white",
    "cement",
    "anvil",
    "ice",
    "lagoon",
    "limoncello",
    "off-black",
    "transparent"
  ]),

  /**
   * A single button displayed below the logos.
   */
  button: PropTypes.any,

  /**
   * Specifies additional class name.
   */
  className: PropTypes.string,

  /**
   * Specifies an array of logos.
   */
  logos: PropTypes.arrayOf(
    PropTypes.shape({
      alt: PropTypes.string,
      link: PropTypes.string,
      src: PropTypes.string.isRequired
    })
  ),

  /**
   * Specifies an optional label above the logo train.
   */
  label: PropTypes.string,

  /**
   * Specifies an optional title above the logo train.
   */
  title: PropTypes.string
}

export default LogoTrain
