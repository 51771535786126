import PropTypes from "prop-types"
import classNames from "classnames/bind"

// ---------------------------------------------------------

import Image from "@components/image"
import ErrorBoundary from "@components/error-boundary"

// ---------------------------------------------------------

import styles from "./styles.module.scss"

// ---------------------------------------------------------

const Grid = ({
  anchorId,
  backgroundAngle,
  backgroundColor,
  backgroundImage,
  centerText,
  children,
  className,
  column,
  header,
  headerSize,
  isUneven,
  isRounded,
  label,
  paddingTop = "80",
  paddingBottom = "80",
  reversedOnMobile,
  verticalAlignment = "top",
  width
}) => {
  const angleSize = backgroundAngle?.split("-")[0]
  const angleReversed = backgroundAngle?.split("-")[1]
  const containerClasses = classNames(styles.grid_container, {
    [styles[`padding_top_${paddingTop}`]]: paddingTop,
    [styles[`padding_bottom_${paddingBottom}`]]: paddingBottom,
    [`background_${backgroundColor}`]: backgroundColor,
    [styles[`background_angle`]]: backgroundAngle,
    [styles[`background_angle_${angleSize}`]]: angleSize,
    [styles[`background_angle_${angleReversed}`]]: angleReversed
  })

  const contentClasses = classNames(styles.grid_content, className, {
    [styles[`content_alignment_${verticalAlignment}`]]: verticalAlignment,
    [styles[`column_${column}`]]: column,
    [styles[`reversed_on_mobile`]]: reversedOnMobile,
    [styles[`is_uneven`]]: isUneven,
    [styles[`center_text`]]: centerText,
    [styles[`width_${width}`]]: width
  })

  const gridContent = (
    <>
      {label && <p className={styles.label}>{label}</p>}
      {header ? (
        headerSize == "Medium" ? (
          <h3 className={styles.header}>{header}</h3>
        ) : (
          <h2 className={styles.header}>{header}</h2>
        )
      ) : null}
      <div className={contentClasses}>{children}</div>
    </>
  )

  return (
    <div className={containerClasses} id={anchorId}>
      {backgroundImage?.src ? (
        <Image
          src={backgroundImage.src}
          alt=""
          quality={100}
          fill
          sizes="100vw"
          style={{
            objectFit: "cover"
          }}
        />
      ) : null}
      <ErrorBoundary>
        {isRounded ? <div className={styles.is_rounded}>{gridContent}</div> : gridContent}
      </ErrorBoundary>
    </div>
  )
}

Grid.propTypes = {
  /**
   * Specifies an id for the container div.
   */
  anchorId: PropTypes.string,

  /**
   * Specifies the background angle.
   */
  backgroundAngle: PropTypes.oneOf([
    "small",
    "medium",
    "large",
    "small-reversed",
    "medium-reversed",
    "large-reversed"
  ]),

  /**
   * Specifies the background color.
   */
  backgroundColor: PropTypes.oneOf([
    "anvil",
    "cement",
    "ice",
    "ice-light",
    "lagoon",
    "dusk",
    "limoncello",
    "off-black",
    "white",
    "midnight-gradient",
    "midnight-gradient-reversed",
    "ice-gradient",
    "ice-gradient-reversed",
    "dusk-gradient",
    "deepest-pink-gradient",
    "dusk-to-black-gradient",
    "black-to-dusk-gradient"
  ]),

  /**
   * Source URL for background image. When specified, overrides `backgroundColor` prop.
   */
  backgroundImage: PropTypes.shape({
    src: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    alt: PropTypes.string
  }),

  /**
   * Specifies if text in this grid should be centered.
   */
  centerText: PropTypes.bool,

  /**
   * Specifies anything between the opening and closing tag.
   */
  children: PropTypes.node,

  /**
   * Additional classes to add when used in another component/template.
   */
  className: PropTypes.string,

  /**
   * Specifies the number of columns in  grid.
   */
  column: PropTypes.oneOf([1, 2, 3, 4]),

  /**
   * Renders header of content within grid.
   */
  header: PropTypes.string,

  /**
   * Specifies header size.
   */
  headerSize: PropTypes.string,

  /**
   * Specifies if the grid items should be in a rounded white container.
   */
  isRounded: PropTypes.bool,

  /**
   * Specifies if columns should be 60-40% widths.
   *
   * Note: this only works if the value of column is "2".
   */
  isUneven: PropTypes.bool,

  /**
   * Specifies an optional label above the header.
   */
  label: PropTypes.string,

  /**
   * Specifies the top padding.
   *
   */
  paddingTop: PropTypes.oneOf(["0", "40", "80", "120"]),

  /**
   * Specifies the bottom padding.
   *
   */
  paddingBottom: PropTypes.oneOf(["0", "40", "80", "120"]),

  /**
   * Specifies if content should be reversed on mobile.
   */
  reversedOnMobile: PropTypes.bool,

  /**
   * Specifies how the content should be aligned with its container.
   */
  verticalAlignment: PropTypes.oneOf(["top", "bottom", "center"]),

  /**
   * Specifies the width of child(ren) in the grid on desktop screens.
   */
  width: PropTypes.oneOf(["default", "medium", "narrow"])
}

export default Grid
